import { render } from "./success.vue?vue&type=template&id=79c23df7&scoped=true"
import script from "./success.vue?vue&type=script&lang=js"
export * from "./success.vue?vue&type=script&lang=js"

import "./success.vue?vue&type=style&index=0&id=79c23df7&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-79c23df7"
/* hot reload */
if (module.hot) {
  script.__hmrId = "79c23df7"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('79c23df7', script)) {
    api.reload('79c23df7', script)
  }
  
  module.hot.accept("./success.vue?vue&type=template&id=79c23df7&scoped=true", () => {
    api.rerender('79c23df7', render)
  })

}

script.__file = "src/views/shop/order/success/success.vue"

export default script