<template>
    <div class="wrapper">
      <headBack>
        <template v-slot:title>
          <div class='title'>
            提交成功
          </div>
        </template>
      </headBack>
      <div class='header_cont'></div>
      <div class="s_box">
        <img class="success" src="https://static.yihu365.cn/img/h5Img/shop/zfcg.png" alt="">
        <div>订单支付成功</div>
        <div class="btn_box">
          <van-button type="success" @click="toHome">返回首页</van-button>
          <van-button type="default" @click="toOrder">查看订单</van-button>
        </div>
      </div>
    </div>
</template>

<script>
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
export default {
  name: "success",
  setup(){
    const router = useRouter()
    const toHome =()=> {
      router.push({
        name:'shop'
      })
    }
    const toOrder =()=> {
      router.push({
        name:'shopOrder'
      })
    }
    return{
      toHome,
      toOrder
    }
  }
}
</script>

<style scoped lang="scss">
     .wrapper {
       //font-weight: 500;
       color: #333333;
       .s_box{
         text-align: center;
       }
         .success{
           margin-left: 50px;
            width:260px;
         }
       .btn_box{

         display: flex;
         justify-content: space-around;
         margin-top: 80px;

         button{

           font-size: 22px;
         }
         ::v-deep(.van-button){
           line-height: 80px;
           height:80px;
         }
         ::v-deep(.van-button--normal){
           padding:0 50px;
         }
       }
     }
</style>
